.ribbon {
  font-size: 13px;
  color: #ffffff;
  font-family: "Poppins", "sans-serif";
}
.ribbon {
  --f: 0.5em; /* control the folded part */
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #526754;
}
