@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* removing the number arrows in number field */

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

/* custom select */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='24' height='24' fill='white'></rect><path d='M17 9.5L12 14.5L7 9.5' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  background-blend-mode: darken;
}

/* green color for slider */
#address_type {
  accent-color: #526754;
}

#address_check {
  accent-color: rgb(37 99 235);
}

/* label custom style */

label {
  font-size: 13px !important;
  color: rgb(55 65 81) !important;
}

/* country dropdown styles */

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: 1px solid #9c9c9c !important;
  border-radius: 5px 0 0 5px !important;
  border-right: none !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid transparent !important;
  width: 105px !important;
  height: 36px !important;
  top: -10px !important;
  left: -10px !important;
  background-image: url("data:image/svg+xml;utf8,<svg width='18px' height='18px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='24' height='24' fill='none'></rect><path d='M17 9.5L12 14.5L7 9.5' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 5px;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid transparent !important;
}
.react-tel-input {
  width: auto !important;
}

.react-tel-input .form-control {
  border: 1px solid #9c9c9c !important;
  width: 100% !important;
  border-radius: 5px !important;
  padding-left: 35px !important;
}
.input-width-important {
  width: 100px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
  border-radius: 3px 0 0 0;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

/* side bar styles */

.ps-sidebar-container {
  background: #ffffff !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  height: 100%;
  position: relative;
  z-index: 40 !important;
  padding: 0px !important;
}

.ps-menu-icon {
  margin: 0px !important;
}

.ps-menu-button {
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.ps-menu-label {
  display: flex;
  justify-content: space-between;
}


/* infinitescroll class */

.infinite-scroll-component {
  overflow: unset !important;
}
/* custom loading indicator */
.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
  color: #526754;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
